<div class="container-fluid">
  <div
    [ngClass]="{
      'page-header': title !== '',
      'page-header-2': title === ''
    }"
  >
    <div class="row">
      <div class="col-lg-6">
        <div class="page-header-left">
          <purpl-dashboard-title
            [title]="headerTitle || title"
            [position]="user?.position"
            [hasBackButton]="hasBackButton"
            [details]="details"
          ></purpl-dashboard-title>
        </div>
      </div>
      <div class="col-lg-6">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
