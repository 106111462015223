import { IUser } from './User';

export const KYCType = {
  IDENTIFICATION: 'identification',
  IDENTITY: 'identity',
  COMPLIANCE_CHECK: 'compliance_check',
  ADDITIONAL_INFORMATION: 'additional_information',
  AML: 'aml',
  FINAL: 'final',
};

export const CredentialsType = {
  ID: 'id',
  FACEMAPE: 'facemap',
  SELFIE: 'selfie',
};

export const CredentialsSubType = {
  FACE: 'face',
  FRONT: 'front',
  ORIGINAL_FRONT: 'original_front',
  PHOTO: 'photo',
  BACK: 'back',
  LIVENESS: 'liveness',
};

export type IKYCType =
  | 'identification'
  | 'identity'
  | 'compliance_check'
  | 'additional_information'
  | 'aml'
  | 'final';

export type ComplianceCredentialsType = 'id' | 'facemap' | 'selfie';
export type ComplianceCredentialsSubType =
  | 'face'
  | 'front'
  | 'back'
  | 'liveness';
export interface Compliance {
  attachments: IComplianceAMLAttachment[];
  completed_aml_check: boolean;
  current_day_cumulative_amount_received: number;
  current_month_cumulative_amount_received: number;
  current_year_cumulative_amount_received: number;
  date_of_birth: string;
  extra_data: any;
  father_name: string;
  first_name: string;
  first_name_en: string;
  id: string;
  id_issuance_country: string;
  last_edited_by: LastEditedBy;
  last_name: string;
  last_name_en: string;
  nationality: string;
  solved: boolean;
  total_cumulative_amount_received: number;
  updated_at: string;
  user_id: string;
  using_jumio_v2: boolean;
  verification_comments: string;
  verification_comments_blf: string;
  verification_status: string;
  verified_by_blf_at: string;
  verified_by_jumio_at: string;
}

export interface LastEditedBy {
  first_name: string;
  id: number;
  last_name: string;
  position: string;
}

export interface IComplianceAMLAttachment {
  content_type: string;
  doc_type: string;
  id: string;
  uploaded_at: string;
  uploaded_by: LastEditedBy;
  url: string;
}

export interface ComplianceCredentials {
  id: number;
  type: ComplianceCredentialsType;
  subtype: ComplianceCredentialsSubType;
  image: string;
  workflow_id: string;
  datetime_taken: string;
  datetime_created: string;
  datetime_updated: string;
}

export interface ComplianceVerifications {
  type: IKYCType;
  status: string;
  verified_data: any | null;
  decision: {
    result: string;
    datetime_performed: string;
  } | null;
  provider: {
    code: string;
    display_name: string;
    version: string;
  };
}
