import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { CountToModule } from 'angular-count-to';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { SharedModule } from '../../shared/shared.module';
import { BrandManagerDashboardComponent } from './pages/brand-manager/brand-manager-dashboard.component';
import { AdminDashboardComponent } from './pages/admin/admin-dashboard.component';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { BrandManagerDashboardDateFilterComponent } from './pages/brand-manager/components/brand-manager-dashboard-date-filter/brand-manager-dashboard-date-filter.component';
import { BrandManagerDashboardUserDetailsComponent } from './pages/brand-manager/components/brand-manager-dashboard-user-details/brand-manager-dashboard-user-details.component';
import { PurpleDatePickerModule } from 'src/app/shared/components/purple-date-picker/purple-date-picker.module';
import { AppExportButtonModule } from 'src/app/shared/components/app-export-button/app-export-button.module';
import { PurplTableModule } from 'src/app/shared/components/purpl-table/purpl-table.module';
import { BreadcrumbModule } from '../../shared/components/breadcrumb/breadcrumb.module';
import { DashboardTabsComponent } from './components/dashboard-tabs/dashboard-tabs.component';
import { BrandManagerDashboardAllUsersComponent } from './pages/brand-manager/components/brand-manager-dashboard-all-users/brand-manager-dashboard-all-users.component';
import { PageLimiterModule } from 'src/app/shared/components/page-limiter/page-limiter.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    DashboardComponent,
    BrandManagerDashboardComponent,
    AdminDashboardComponent,
    BrandManagerDashboardDateFilterComponent,
    BrandManagerDashboardUserDetailsComponent,
    DashboardTabsComponent,
    BrandManagerDashboardAllUsersComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    NgbModule,
    CountToModule,
    SharedModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    NgbNavModule,
    PurpleDatePickerModule,
    AppExportButtonModule,
    PurplTableModule,
    BreadcrumbModule,
    PageLimiterModule,
    MatDialogModule,
  ],
  exports: [DashboardTabsComponent],
})
export class DashboardModule {}
