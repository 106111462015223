import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { findLastIndex } from 'lodash';
import { SuspendTransactionModalConfirmationComponent } from 'src/app/pages/suspensions/components/suspend-transaction-modal-confirmation/suspend-transaction-modal-confirmation.component';
import { ListingBaseComponent } from '../base/listing-base.component';

@Component({
  selector: 'app-received-transactions-modal',
  templateUrl: './received-transactions-modal.component.html',
  styleUrls: ['./received-transactions-modal.component.scss'],
})
export class ReceivedTransactionsModalComponent
  extends ListingBaseComponent
  implements OnInit
{
  @Input() modal: any;

  transaction: any;
  transactionStatus?: string;
  transactionDate?: string;
  cashoutLocation?: string;

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      transaction: any;
    }
  ) {
    super(injector);
    this.transaction = data.transaction;
  }

  ngOnInit(): void {
    let updated_at: string = '';
    const transaction_status = this.transaction?.transaction_status;
    const status_history: any[] = this.transaction?.status_history;

    if (status_history) {
      const status_history_index = findLastIndex(status_history, (item) => {
        return item.status.indexOf(transaction_status) !== -1;
      });
      updated_at = status_history[status_history_index]?.updated_at;
    }
    this.transactionDate = updated_at;

    if (this.transaction?.transaction_status === 'Cashed Out') {
      this.cashoutLocation = this.transaction?.final_cashout_point
        ? this.transaction?.final_cashout_point
        : this.transaction?.branch_dto.name;
    } else {
      this.cashoutLocation = 'N/A';
    }

    switch (this.transaction?.transaction_status) {
      case 'Received':
        this.transactionStatus = 'Received';
        break;
      case 'Cashing Out':
        this.transactionStatus = 'Issuance';
        break;
      case 'Cashed Out':
        this.transactionStatus = 'Completed';
        break;
      case 'Expired':
        this.transactionStatus = 'Expired';
        break;
      case 'Cancelled':
        this.transactionStatus = 'Cancelled';
        break;
      case 'On Hold':
        this.transactionStatus = 'Suspended';
        break;
      default:
        break;
    }
  }

  onSuspendTransaction(isSuspend: boolean) {
    this.dialog.open(SuspendTransactionModalConfirmationComponent, {
      data: {
        isSuspend: !isSuspend,
        transaction: this.transaction,
      },
      width: '500px',
    });
  }
}
