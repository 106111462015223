import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    data: {
      title: 'Dashboard',
    },
  },
  {
    path: 'users-dashboard',
    loadChildren: () =>
      import(
        '../../pages/dashboard/pages/user-comparison-dashboard/user-comparison-dashboard.module'
      ).then((m) => m.UserComparisonDashboardModule),
    data: {
      title: 'Dashboard',
    },
  },
  {
    path: 'transaction-comparison',
    loadChildren: () =>
      import(
        '../../pages/dashboard/pages/transaction-comparison/transaction-comparison.module'
      ).then((m) => m.TransactionComparisonModule),
    data: {
      title: 'Dashboard',
    },
  },
  {
    path: 'user-comparison-dashboard',
    loadChildren: () =>
      import(
        '../../pages/dashboard/pages/user-comparison-dashboard/user-comparison-dashboard.module'
      ).then((m) => m.UserComparisonDashboardModule),
  },
  {
    path: 'senders-analytics',
    loadChildren: () =>
      import('./pages/analytics-senders/analytics-senders.module').then(
        (m) => m.AnalyticsSendersModule
      ),
    data: {
      title: 'Senders Analytics',
    },
  },
  {
    path: 'users-comparison',
    loadChildren: () =>
      import(
        '../../pages/dashboard/pages/users-comparison/users-comparison.module'
      ).then((m) => m.UsersComparisonModule),
  },
  {
    path: 'cashout-performance',
    loadChildren: () =>
      import('./pages/cashout-performance/cashout-performance.module').then(
        (m) => m.CashoutPerformanceModule
      ),
    data: {
      title: 'Dashboard',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
